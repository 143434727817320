const therapists = [
  {
    route: 'annabella',
    name: 'Annabella Hagen',
    credentials: 'LCSW',
    education1: 'Master of Social Work - Brigham Young University',
    education2: 'Bachelor of Science – Psychology Major – Brigham Young University',
    info:
      'Acceptance and Commitment Therapy (ACT) | Exposure and Response Prevention (ERP) | Cognitive Behavioral Therapy (CBT) | Mindfulness | Trauma-Focused Cognitive Behavioral Therapy (TF-CBT) | Parent Coaching | Presenter, International OCD Foundation Annual Conference | Graduate of the Behavior Therapy Training Institute (BTTI) and Member, IOCD Foundation | Member, ACBS (Association for Contextual Behavioral Science)',
    image: 'v1680227558/annabella_new.png',
    metaDescription:
      'I am the owner and clinical director for Mindset Family Therapy in Provo, Utah. For nearly 20 years, I have worked with children, adolescents, and adults struggling with anxiety disorders, behavioral and family issues. I began my private practice to better serve the community in response to the lack of local specialized expertise. I have experienced overwhelming success in treating OCD, OC spectrum disorders, and other anxiety disorders.',
  },
  // Commenting out intentionally as she might be returning
  // {
  //   route: 'rachel',
  //   name: 'Rachel Blaylock',
  //   credentials: 'LCSW, RPT',
  //   education1: 'Master of Social Work - Brigham Young University',
  //   education2: 'Bachelor of Social Work - Brigham Young University',
  //   info: 'Member, National Association of Social Workers | Member, Association for Play Therapy',
  //   image: 'v1596384420/rachel.png',
  //   metaDescription:
  //     'I love working with women who desire to build healthy relationships with others and with themselves. I also enjoy working with individuals who experience anxiety or perfectionism and helping them find ways to overcome those challenges. It is an honor to see individuals connect with their inherent worth and make meaningful changes in their lives.',
  // },
  {
    route: 'christine',
    name: 'Christine Black',
    credentials: 'LCSW',
    education1: 'Master of Social Work — Brigham Young University',
    education2: 'Bachelor of Science - Psychology - Brigham Young University',
    info:
      'Acceptance and Commitment Therapy (ACT) | Exposure and Response Prevention (ERP) | Cognitive Behavioral Therapy (CBT) | Mindfulness | Play Therapy | Child' +
      ' Parent Relationship Training | Expressive' +
      ' Arts Therapy',
    image: 'v1614128539/christine_b.png',
    metaDescription:
      'As a therapist, I aim to create a safe space where the client can simultaneously feel seen and accepted, and find hope and healing through genuine connection. I believe that people have an inherent ability to move toward healing, and I feel honored to be part of a client’s journey to authentic self-discovery and self-acceptance.',
  },
  {
    route: 'jon',
    name: 'Jon Case',
    credentials: 'LCSW',
    education1: 'Master of Social Work — Brigham Young University',
    education2: 'Bachelor of Science - Psychology - Brigham Young University',
    info:
      'Acceptance and Commitment Therapy (ACT) | Social Thinking | Attachment Theory | Solution Focused | Exposure and Response Prevention (ERP) | Motivational Interviewing | Mindfulness | Parent Coaching | Member, ACBS (Association for Contextual Behavioral Science) | Member, IOCD Foundation | Co-Presenter, International OCD Foundation Annual Conference',
    image: 'v1634596900/jon_c.png',
    metaDescription:
      'I offer a gentle approach to therapy. I will support you in discovering your values and choosing a life for yourself that is resonant with your values. In that pursuit, we will develop skills together to help you stay in contact with the present moment, to be grounded and alert to your experiences, and increasingly resilient in the face of uncomfortable and difficult moments.',
  },
  {
    route: 'erik',
    name: 'Erik Wells',
    credentials: 'LCSW',
    education1: 'Master of Social Work — Brigham Young University',
    education2: 'Bachelor of Science - Spanish and Linguistics - Brigham Young University',
    info:
      'Acceptance and Commitment Therapy (ACT) | Exposure and Response Prevention (ERP) | Mindfulness | Cognitive Behavioral Therapy (CBT) | Trauma Therapy | Motivational Interviewing | Attachment Theory | Trust-Based Relational Intervention (TBRI) | Member, IOCD Foundation | Co-Presenter, International OCD Foundation Annual Conference',
    image: 'v1648599683/erik.png',
    metaDescription:
      'I enjoy the process of therapy and love hearing the unique stories of each individual I meet and strive to truly see and understand. It is my hope that as we work together we can help you gain a richer understanding of yourself and the world around you, connect more deeply with the people that you love, develop a greater sense of meaning and purpose in your life, and feel safe and comfortable just being you.',
  },
  {
    route: 'carrie',
    name: 'Carrie Wendt',
    credentials: 'LCSW',
    education1: 'Master of Social Work — Brigham Young University',
    education2: 'Bachelor of Science - Psychology - Brigham Young University',
    info:
      'Acceptance and Commitment Therapy (ACT) | Exposure and Response Prevention (ERP) | Cognitive Behavioral Therapy (CBT) | Trauma Therapy | Mindfulness | Narrative Therapy | Member, IOCD Foundation',
    image: 'v1655771070/carrie_w_2.png',
    metaDescription:
      'Working with individuals and families to overcome challenges is my passion. As a therapist, I have experience working with children, adolescents, and adults facing emotional and behavioral concerns. I find satisfaction in helping people develop skills to treat symptoms of OCD, anxiety, depression, trauma, and relationship challenges.',
  },
  {
    route: 'tanya',
    name: 'Tanya Rummler',
    credentials: 'LCSW',
    education1: 'Master of Social Work — Brigham Young University',
    education2: 'Bachelor of Science - Elementary Education - Utah State University',
    info:
      'Graduate of the Pediatric Behavior Therapy Training Institute for OCD (BTTI) | Exposure Response Prevention (ERP) | Inference Based Cognitive Behavioral Therapy (ICBT) | Cognitive Behavioral Therapy (CBT) | Dialectical Behavioral Therapy (DBT) | Play Therapy | Member of International OCD Foundation',
    image: 'v1682277680/tanya.png',
  },
  {
    route: 'brynlee',
    name: 'Brynlee Furnell',
    credentials: 'CSW',
    education1: 'Master of Social Work — Brigham Young University',
    education2: 'Bachelor of Science in Psychology - Southern Utah University',
    info:
      'Acceptance and Commitment Therapy (ACT) | Exposure and Response Prevention (ERP) | Trauma Therapy | Cognitive Behavioral Therapy (CBT) | Mind-Body Bridging (MBB) | Emotionally-Focused Therapy (EFT) | Internal Family Systems (IFS) | Accelerated Resolution Therapy (ART) | Member, IOCD Foundation',
    image: 'v1727388537/brynlee.png',
  },
];

module.exports = therapists;
